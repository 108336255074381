<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-start>
      <v-flex xs12 text-left>
        <span style="color: #000; font-size: 20px; font-family: poppinsbold"
          >User Booking Report</span
        >
      </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-start>
          <v-flex xs12 sm6 md3 pa-5>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="checkDate1(), (menu1 = false)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md3 pa-5>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="checkDate(), (menu2 = false)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 xl4 pa-5>
            <v-select
              v-model="programme"
              :items="userList"
              item-text="name"
              item-value="value"
              outlined
              dense
              label="User"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 xl4 pa-5>
            <v-select
              v-model="programmee"
              :items="programs"
              item-text="name"
              item-value="value"
              outlined
              dense
              label="Program"
            ></v-select>
          </v-flex>

          <v-flex
            align-self-start
            xs12
            text-right
            px-3
          >
            <a
              target="_blank"
              :href="
                'https://api.wayanadwildlifesanctuary.com/booking/agentbookingreportpdf?from=' +
                fromDate +
                '&to=' +
                toDate +
                '&user=' +
                programme +
                '&programme=' +
                programmee
              "
              class="downlink"
            >
              <span
                style="
                  font-size: 16px;
                  font-family: poppinssemibold;
                  color: #68d389;
                "
              >
                Download Pdf</span
              >
            </a>
          </v-flex>
          <!-- <v-flex v-if="programme" xs12 sm6 md3 lg3 xl3 pa-5>
            <v-select
              v-model="slotDetail"
              :items="slotItems"
              item-text="startTime"
              item-value="_id"
              item-color="#8d8d8d"
              color="#8d8d8d"
              outlined
              dense
              label="Slots"
            >
              <template v-slot:selection="{ item }">
                <span>
                   {{ item.startTime}} -
                  {{ item.endTime }}
                </span>
              </template>
              <template v-slot:item="{ item }">
                <span>
                 {{ item.startTime}} -
                  {{ item.endTime }}
                </span>
              </template>
            </v-select>
          </v-flex> -->
          <!--           
      <v-flex xs12 align-self-end>
        <v-layout wrap justify-end> -->

          <!-- <v-flex md3>
            <v-layout wrap justify-end pt-6>
              <v-flex xs12 sm6 md6 lg6 xl6>
                 <a
              target="_blank"
              :href="
                'https://api.wayanadwildlifesanctuary.com/booking/agentbookingreportpdf?from=' +
                fromDate +
                '&to=' +
                toDate +
                '&user=' +
                programme +
                '&programme=' +
                programmee
              "
              class="downlink"
            >
              <span
                style="
                  font-size: 16px;
                  font-family: poppinssemibold;
                  color: #68d389;
                "
              >
                Download Pdf</span
              >
            </a>
              </v-flex>
              <v-flex xs12 sm6 md6 lg6 xl6 align-self-end>
                <download-excel :data="json_data" :fields="json_fields">
                  <span
                    style="
                      font-size: 16px;
                      font-family: poppinssemibold;
                      color: #68D389;
                      cursor: pointer;
                    "
                  >
                    Download Excel</span
                  >
                </download-excel>
              </v-flex>
            </v-layout>
          </v-flex> -->
          <!-- </v-layout>
      </v-flex> -->
        </v-layout>
      </v-flex>
      <!-- <v-flex xs12>
        <v-layout wrap justify-center pt-4>
          <v-flex>
            <v-tabs
              fixed-tabs
              v-model="tabValue"
              color="#68D389"
              centered
              slider-color="yellow"
            >
              <v-tab v-for="item in titleArray" :key="item.value">
                {{ item.title }}
              </v-tab>
            </v-tabs>
          </v-flex>
        </v-layout>
      </v-flex> -->

      <v-flex xs12>
        <v-layout wrap>
          <v-flex v-if="bookingData.length > 0" xs12 pt-4 text-left>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >User Booking Summary</span
            >
            <ProgramBooking
              :bookingData="bookingData"
              :fromDate="fromDate"
              :toDate="toDate"
              :programme="programme"
              :programmee="programmee"
              :pages="pages"
              @stepper="winStepper"
            />
          </v-flex>

          <v-flex v-else xs12 text-left pa-4>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >User Booking Summary</span
            >
            <br />
            <span class="textField2"> No Bookings Found !! </span>
          </v-flex>
        </v-layout>
      </v-flex>

      <!-- <v-flex xs12 v-if="tabValue == 1">
        <v-layout wrap>
          <v-flex v-if="reservationData.length > 0" xs12 pt-4 text-left>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >Reservations</span
            >
            <Reservation
              :reservationData="reservationData"
              :fromDate="fromDate"
              :toDate="toDate"
              :programme="programme"
              :pages="pages"
              @stepper="winStepper"
            />
          </v-flex>
          <v-flex v-else xs12 text-left pa-4>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >Reservations</span
            >
            <br />
            <span class="textField2"> No Reservations Found !! </span>
          </v-flex>
        </v-layout>
      </v-flex> -->
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ProgramBooking from "./userReport";
// import Reservation from "./reservation";
export default {
  components: {
    // Reservation,
    ProgramBooking,
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      ServerError: false,
      timeout: 5000,
      msg: null,
      bookingData: [],
      reservationData: [],
      programme: null,
      programmee: null,
      slotDetail: null,
      programeData: [],
      details: [],
      slotDetails: [],
      guest: [],
      programs: [],
      slotItems: [],
      panel: [],
      disabled: false,
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      currentPage: 1,
      pages: 0,
      userTypes: [
        { name: "Indian", reference: "indian", value: 0, guest: [] },
        {
          name: "Foreigner",
          reference: "foreigner",
          value: 0,
          guest: [],
        },
        {
          name: "Children",
          reference: "children",
          value: 0,
          guest: [],
        },
      ],

      json_fields: {
        Date: "date",
        ProgrameName: "progName",
        "Total Seats": "totalCount",
        "booked Seats": "bookedCount",
        "Reserved Seats": "reservedCount",
        "Free Seats": "freeCount",
      },
      json_fieldsReservation: {
        ProgrameName: "programmeName",
        BookingDate: "bookingDate",
        "Seats Reserved": "reserved",
        StartTime: "slotDetail.startTime",
        EndTime: "slotDetail.endTime",
      },
      json_data: [],
      json_dataReservation: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      date: new Date(),
      tabValue: 0,
      titleArray: [
        { title: "BOOKINGS", value: "booking" },
        // { title: "RESERVATIONS", value: "reservation" },
      ],
      type: null,
      userList: [],
    };
  },

  beforeMount() {
    // this.getExcel();
    // this.getData();

    // if (this.dateProp != "dateWisePrograms") this.date = null;
    // this.getData();
    if (this.$route.query.user) {
      this.getProgramme();
      this.getProgrammee();
      this.fromDate = null;
      this.toDate = null;
    } else {
      this.getProgramme();
      this.getProgrammee();
      if (this.fromDate) {
        this.fromDate.setDate(this.fromDate.getDate() - 15);
        this.fromDate = this.fromDate.toISOString().substr(0, 10);
      }
      if (this.toDate) {
        this.toDate.setDate(this.toDate.getDate());
        this.toDate = this.toDate.toISOString().substr(0, 10);
      }
    }
  },
  watch: {
    // tabValue() {
    //   if (this.tabValue == 0) {
    //     this.getData();
    //   } else if (this.tabValue == 1) this.getReservations();
    // },
    fromDate() {
      if (this.fromDate == null) this.getData();
      // this.checkDate1();
      // this.getData();
      // this.getExcel();
    },
    toDate() {
      if (this.toDate == null) this.getData();
      // this.checkDate();
      // if (this.fromDate)
      // this.getData();
      // this.getExcel();
    },

    programme() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      // this.getSlot();
      // this.getExcel();
    },
    programmee() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      // this.getSlot();
      // this.getExcel();
    },
    // slotDetail() {
    //   this.getData();
    //   if(this.tabValue==1)
    //   this.getReservations()
    //   this.getExcel();
    // },
  },
  methods: {
    checkDate() {
      // let ndate = new Date().toISOString().substr(0, 10);
      // if (this.fromDate == ndate) {
      const tomorrow = new Date(this.toDate);
      tomorrow.setDate(tomorrow.getDate() - 30);
      this.fromDate = tomorrow.toISOString().substr(0, 10);
      // }
      this.getData();
    },
    checkDate1() {
      // let ndate = new Date().toISOString().substr(0, 10);
      // if (this.fromDate == ndate) {
      const tomorrow = new Date(this.fromDate);
      tomorrow.setDate(tomorrow.getDate() + 30);
      this.toDate = tomorrow.toISOString().substr(0, 10);
      // }
      this.getData();
    },
    winStepper(windowData) {
      if (windowData) {
        this.currentPage = windowData.currentPage;
        if (windowData.pages) this.pages = windowData.pages;
        this.type = windowData.type;
        this.getData();
      }
    },
    getUserType(item) {
      var userType = this.userTypes.find((userItem) => {
        return userItem.reference == item;
      });
      return userType;
    },
    getData() {
      // alert("Parent getData");
      if (this.currentPage && this.programme) {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/booking/agentbookingreport",
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            from: this.fromDate,
            to: this.toDate,
            user: this.programme,
            programme: this.programmee,
            // slotDetail: this.slotDetail,
            count: 12,
            page: this.currentPage,
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.bookingData = response.data.data;
              this.excel = response.data.data;
              this.json_data = this.excel;
              // this.reservationData = response.data.reservations;
              this.pages = response.data.pages;

              // this.excel = response.data.data;
              // this.json_data = this.excel;
              this.appLoading = false;
            } else {
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    getProgramme() {
      this.appLoading = true;
      axios({
        url: "/user/getlist",
        method: "GET",
        params: { role: "guest" },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            // this.userList=response.data.data
            var temp = [
              {
                name: null,
                value: null,
              },
            ];
            temp = response.data.data;
            for (var i = 0; i < temp.length; i++) {
              temp[i].name = temp[i].guestData.name;
              temp[i].value = temp[i]._id;
            }
            this.userList = temp;
            if (this.$route.query.user) this.programme = this.$route.query.user;
            else this.programme = this.userList[0]._id;
            // this.programs.unshift({ name: "All Programs", value: null });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProgrammee() {
      this.appLoading = true;
      axios({
        url: "/programme/getlist",
        method: "GET",
        params: { programmestatus: "started" },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            var temp = [
              {
                name: null,
                value: null,
              },
            ];
            temp = response.data.data;
            for (var i = 0; i < temp.length; i++) {
              temp[i].name = temp[i].progName;
              temp[i].value = temp[i]._id;
            }
            this.programs = temp;
            this.programs.unshift({ name: "All Programs", value: null });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // getSlot() {
    //   this.appLoading = true;
    //   axios({
    //     url: "/programme/slots/getlist",
    //     method: "GET",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     params: {
    //       programme: this.programme,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         this.appLoading = false;
    //         this.slotItems = response.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    formatDate(item) {
      var dt = new Date(item);
      // var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
  },
};
</script>
<style>
.head {
  font-family: poppinsregular;
  font-size: 14px;
}
.content {
  font-family: poppinsbold;
  font-size: 13px;
}
</style>